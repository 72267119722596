<div *ngIf="form" class="upsert-pane">
  <nb-card *ngFor="let fieldType of fieldTypes; let index = index" class="details-card">
    <nb-card-header>{{ titles[index] | translate }}</nb-card-header>
    <nb-card-body>
      <form [formGroup]="form">
        <div class="field-pane {{field.type}}-input" *ngFor="let field of fieldType; let index = index">
        <span [ngSwitch]="field.type">
          <span *ngSwitchCase="'text'">
            <span class="input-label">{{ field.label | translate }}</span>
            <input type="text" nbInput fullWidth placeholder="{{field.label | translate}}" [formControlName]="field.name">
          </span>
          <span *ngSwitchCase="'textarea'">
            <span class="input-label">{{ field.label | translate }}</span>
            <textarea nbInput fullWidth placeholder="{{field.label | translate}}" [formControlName]="field.name"></textarea>
          </span>
          <span *ngSwitchCase="'date'">
            <span class="input-label">{{ field.label | translate }}</span>
            <input [min]="min" [nbDatepicker]="datepicker" nbInput fullWidth placeholder="{{field.label | translate}}"
                   [formControlName]="field.name">
            <nb-datepicker #datepicker format="dd-MM-yyyy"></nb-datepicker>
          </span>

          <span *ngSwitchCase="'select'">
            <span class="input-label" *ngIf="field.options">{{ field.label | translate }}</span>
            <nb-select [formControlName]="field.name" *ngIf="field.options">
              <nb-option *ngFor="let option of (field.options ? field.options : ['X']); let oIndex = index"
                         value="{{option.value}}"> {{ option.name|translate }}</nb-option>
            </nb-select>
          </span>

          <span *ngSwitchCase="'checkbox'">
            <nb-checkbox [formControlName]="field.name"> {{ field.label | translate }}</nb-checkbox>
          </span>

          <div *ngSwitchCase="'map'" class="map-pane">
            <google-map [center]="position" [zoom]="8" width="100%" height="200px">
              <map-marker [position]="position" [icon]="icon"></map-marker>
            </google-map>
          </div>

          <span *ngSwitchCase="'files'">
            <ng-container>
              <a class="file" href="{{baseUrl}}uploads/{{file.link}}" target="_blank" *ngFor="let file of data[field.name]; let fileIndex = index"> [{{file.link}}] </a>
            </ng-container>
            <input (change)="onFileChange($event)" formControlName="files" id="files" type="file">
          </span>
        </span>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</div>
<nb-card *ngIf="form">
  <nb-card-footer>
    <button nbButton hero status="primary" (click)="save()">Opslaan</button>
    <button nbButton hero status="danger" (click)="delete()" *ngIf="data">Verwijderen</button>
  </nb-card-footer>
</nb-card>
